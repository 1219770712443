<script setup lang="ts">
const { t } = useI18n()

const items = ref([
  {
    title: t('general.nav_banner.zero_waste_packaging'),
    subtitle: t('general.nav_banner.zero_waste_packaging_info'),
    icon: 'i-mingcute:leaf-line',
  },
  {
    title: t('general.nav_banner.return_policy_title'),
    subtitle: t('general.nav_banner.return_policy_info'),
    icon: 'i-mingcute:back-2-line',
  },
])

const remHeight = 4.5
const translateY = ref(0)
const transitionEnabled = ref(true)

function updateSlide() {
  if (translateY.value === -(remHeight * (items.value.length - 1))) {
    transitionEnabled.value = false
    translateY.value = 0

    nextTick(() => {
      transitionEnabled.value = true
    })
  }
  else {
    translateY.value -= remHeight
  }
}

let intervalId: NodeJS.Timeout | null = null
const intervalTime = 3000

onMounted(() => {
  intervalId = setInterval(updateSlide, intervalTime)
})

onUnmounted(() => {
  intervalId && clearInterval(intervalId)
})
</script>

<template>
  <div />
</template>
